
import { defineComponent, computed, WritableComputedRef } from "vue";
import UserModule from "../store/modules/User/user";
import UIConfigurationModule from "../store/modules/UIConfiguration/uiConfiguration";

export default defineComponent({
  name: "CartLimitExceededDialog",
  setup () {
    const currentCartItem = computed(() => UserModule.displaySettings.cartItemLimit);
    const initialCartLimit = UserModule.displaySettings.cartItemLimit;

    const isVisible: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return UIConfigurationModule.cartLimitExceededDialogVisibility;
      },
      set (setVisibility: boolean): void {
        UIConfigurationModule.setCartLimitExceededDialogVisibility(setVisibility);
      },
    });

    const dialogBody = computed(() => {
      let message = "";
      if (initialCartLimit > currentCartItem.value) {
        message = message + `<b>${initialCartLimit - currentCartItem.value}</b> of <b>${initialCartLimit}</b> titles already processed.`;
      }

      if (currentCartItem.value > 0) {
        message = message + "<br /><br />First unselect a title before trying to select a new one.";
      }
      return message;
    });

    return {
      currentCartItem,
      isVisible,
      initialCartLimit,
      dialogBody,
    };
  },
});
